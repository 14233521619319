import React from "react";
import { Link } from "gatsby";
import Container from "../components/container";
import Heading from "../components/heading";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Tier from "../components/tier";

const NotFoundPage = () => (
  <Layout showMenu={false}>
    <SEO title="Thank you!" />
    <Tier id="basic">
      <Container>
        <Heading element="1" type="1">
          Thank you!
        </Heading>
        <Heading element="2" type="2">
          Your form submission has been received.
        </Heading>
      </Container>
    </Tier>
    <Tier id="basic">
      <Container>
        <Heading element="2" type="2">
          <Link to="/">← Back to my site</Link>
        </Heading>
      </Container>
    </Tier>
  </Layout>
);

export default NotFoundPage;
